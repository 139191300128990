<nav ngbNav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="">
      {{title}}
    </a>
  </div>
  <!-- <button (click)="navbarCollapsed = !navbarCollapsed" [attr.aria-expanded]="!navbarCollapsed" class="navbar-toggler"
    type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div [ngbCollapse]="navbarCollapsed" class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li ngbNavItem>
        <a ngbNavLink routerLink="">Etusivu</a>
      </li>
      <li ngbNavItem>
        <a ngbNavLink routerLink="api">API</a>
      </li>
      <li ngbNavItem>
        <a ngbNavLink routerLink="ukk">UKK</a>
      </li>
    </ul>
    <ul class="nav navbar-nav">
      <li ngbNavItem>
        <a ngbNavLink routerLink="register">Register</a>
      </li>
      <li ngbNavItem>
        <a ngbNavLink style="cursor: pointer;" (click)="open(login)">Login</a>
      </li>
    </ul>
  </div> -->
</nav>
<div class="body-container">
  <router-outlet></router-outlet>
</div>
<footer class="footer">
  <div class="container">
    <p class="copyright">&copy; {{title}}</p>
  </div>
</footer>
<!-- 
<ng-template #login let-modal>
  <form (submit)="doLogin()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Login</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <p>Please enter your login credentials below. To register, click <a routerLink="register">here</a></p>
        <div class="input-group">
          <input type="text" id="loginUsername" class="form-control" placeholder="Username" name="username"
            autocomplete="username" required>
          <div class="invalid-feedback">Please give your account username</div>
        </div> <br />
        <div class="input-group">
          <input type="password" id="loginPassword" class="form-control" placeholder="Password" name="password"
            autocomplete="current-password" required>
          <div class="invalid-feedback">Please give your account password</div>
        </div> <br />
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">Cancel</button>
      <button type="submit" class="btn btn-success">Login</button>
    </div>
  </form>
</ng-template> -->