<div class="container">
    <div class="row">
        <div class="col-sm-6">
            <h1> Rekisteröidy </h1>
            <p> Rekisteröidy IPv6-tunnelipalveluumme. Rekisteröinti on vaadittua, että voit hallinnoida tunneleita ja
                päivittää IPv4-osoitteesi rajapintamme yli.

            </p>
            <form action="register" id="register" method="POST">
                <div class="form-group" id="register-form-username">
                    <label for="username"> Käyttäjätunnus <a href="#" data-toggle="tooltip" title=""
                            data-trigger="hover" data-original-title="Käyttäjätunnuksen avulla kirjaudut palveluumme">
                            <i class="fa fa-question-circle" aria-hidden="true"></i> </a></label>
                    <input type="text" class="form-control" name="username" id="username" max-length="64"
                        autocomplete="username" required="">
                </div>
                <div class="form-group" id="register-form-email">
                    <label for="email"> Sähköpostiosoite <a href="#" data-toggle="tooltip" title="" data-trigger="hover"
                            data-original-title="Sähköpostiosoitetta käytetään käyttäjän vahvistamiseen, tiedotteisiin sekä salasanan palautukseen">
                            <i class="fa fa-question-circle" aria-hidden="true"></i> </a></label>
                    <input type="email" class="form-control" name="email" id="email" max-length="128"
                        placeholder="käyttäjänimi@esimerkki.fi" autocomplete="email" required="">
                </div>
                <div class="form-group" id="register-form-password">
                    <label for="password"> Salasana <a href="#" data-toggle="tooltip" title="" data-trigger="hover"
                            data-original-title="Salasanan tulee olla vähintään 6 merkkiä pitkä, sisältäen numeron ja ison kirjaimen"
                            aria-describedby="tooltip987884"><i class="fa fa-question-circle"
                                aria-hidden="true"></i></a></label>
                    <input type="password" class="form-control" id="password" name="password" max-length="64"
                        autocomplete="new-password" required="">
                    <label for="password2"> Salasanan vahvistus </label>
                    <input type="password" class="form-control" id="password2" name="password2" max-length="64"
                        autocomplete="new-password" required="">
                </div>
                <div class="form-group" id="register-captcha">
                </div>
                Rekisteröitymällä palveluun hyväksyt <a target="_blank" href="/tos"> käyttöehtomme </a>.
                <br><br> <input type="submit" class="btn btn-primary" id="submit" name="submit" value="Rekisteröidy">
            </form>
            <p> Onko sinulla jo käyttäjätili? Klikka tästä <a href="login"> kirjautuaksesi </a>
            </p>
            <div id="results"> </div>
            <noscript>
                <div class="alert alert-info"> Lomake ei toimi ilman Javascriptiä. Ole hyvä ja ota Javascript käyttöön
                    selaimesi asetuksista. </div>
            </noscript>
        </div>
        <div class="col-sm-6" style="margin-top:50px;">
            <h3> Ohje </h3>
            <p> Rekisteröintijärjestelmämme on yksinkertainen. Syötä haluamasi käyttäjätunnus, toimiva sähköpostiosoite
                ja salasanasi. Suojaamme salasanat kryptaamalla ja suolaamalla salasanat nykyaikaisilla menetelmillä
                tietokantaamme. Voit kirjautua heti luodessasi käyttäjätunnuksesi, mutta jos haluat käyttää palveluamme,
                sinun tulee vahvistaa sähköpostiosoitteesi. Ethän käytä palvelussa keräkäyttöisiä sähköpostiosoitteita,
                sillä pyrimme käyttämään sähköpostiosoitetta tiedottamiseen.
                <br>
                <br> Sinulle lähetetään rekisteröitymisen yhteydessä sähköpostitse aktivointiviesti, joka tulee
                vahvistaa klikkaamalla linkkiä. Vahvistuksen jälkeen voit vapaasti käyttää palveluamme käyttöehtojemme
                mukaisesti.
                <br>
                <br> Jos et saa aktivointiviestiä, kirjaudu ja kokeile vaihtaa sähköpostiosoitetta. Voit pyytää
                aktivointiviestiä lähetettäväksi uudelleen <a href="/activate"> tästä</a>. Tilisi aktivoituu
                automaattisesti kun vahvistat uuden sähköpostiosoitteesi. Muista myös tarkistaa roskapostikansio.
            </p>
        </div>
    </div>
</div>