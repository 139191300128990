<div class="container">
    <h2> API Dokumentaatio </h2>
    <p> Voit käyttää rajapintaamme päivittämään dynaamisen IP-osoitteen palvelimellemme. Tämä on tarpeellista, kun
        sinulla on muuttuva IPv4-osoite. Rajapintamme käyttää DynDNS:n yleistä standardia ja samoja vastauskoodeja.</p>
    <br>API osoite:&nbsp;&nbsp; <code> https://kuusitunneli.fi/api/ </code> ja
    <code> https://kuusitunneli.fi/nic/ </code>
    <br>
    <br> Pakota IPv4: <code>https://ipv4.kuusitunneli.fi/api/ </code>
    <br> Pakota IPv6: <code>https://ipv6.kuusitunneli.fi/api/ </code>
    <br>
    <br> Esimerkki:
    &nbsp;&nbsp;<code>curl -4 --silent --user 'USERNAME:PASSWORD' 'https://kuusitunneli.fi/api/update?hostname=TUNNEL_ID&amp;myip=IPV4-ADDR'</code>
    <br>
    <p></p>
    <h2> Update </h2>
    <p> Käytä tätä kun haluat päivittää julkisen IPv4-osoitteen palvelimelle. Voit käytää komentoa ilman IP-parametriä
        mutta TUNNEL_ID on vaadittu. Jos et määritä IPv4-osoitettasi, käytetään sitä osoitetta millä muodostat yhteyttä.
        Huomaa että jos käytössäsi on IPv6-osoite, vaaditaan IPv4-osoitteen syöttäminen.
        <br>
        <br> Syntaksi:
        <br>
        <br><code> /update?hostname=TUNNEL_ID&amp;myip=IPV4_ADDR </code>
        <br>
    </p>
    <h2> Status </h2>
    <p> Käyttämällä tätä komentoa saat tunnelin tilan selville
        <br>
        <br> Syntaksi:
        <br>
        <br> <code> /status?hostname=TUNNEL_ID </code>
        <br>
    </p>
    <h3> Vastauskoodit</h3>
    <p> Tässä ovat yleiset rajapintamme vastauskoodit. Täälä on vain yksi onnistumista ilmaisema vastaus. Koodi
        <code>good</code> ilmaisee onnistumista. Mikäli saat <code>nochg</code>, tämä tarkoittaa että on jo päivitetty.
        <br>
        <br> <code>badauth</code> - Todennus epäonnistui, tarkista käyttäjätunnus ja salasana.
        <br> <code>good</code> - Päivitys onnistui.
        <br> <code>nochg</code> - Päivitys epäonnistui - Rajapintamme kertoo että päivität liian nopeasti.
        <br> <code>nohost</code> - TUNNEL_ID:tä ei löytynyt käyttäjätililtäsi
        <br> <code>numhost</code> - Liian monta Tunnel_ID:tä.
        <br> <code>toofast</code> - Liian nopeasti yrität päivittää. Yritä 5 minuutin kuluttua uudelleen.
        <br> <code>notfqdn</code> - TUNNEL_ID ei ole validi.
        <br> <code>abuse</code> - TUNNEL_ID on estetty
        <br> <code>dnserr</code> - Teknillinen virhe. Virhe voi johtua siitä että olet yrittämässä päivittää kahdelle
        tunnelille samaa IPv4-osoitetta.
        <br>

    </p>
</div>