import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApiPageComponent } from './api-page/api-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { ShutdownPageComponent } from './shutdown-page/shutdown-page.component';
import { UkkPageComponent } from './ukk-page/ukk-page.component';

const routes: Routes = [
  // { path: 'ukk', component: UkkPageComponent },
  // { path: 'api', component: ApiPageComponent },
  // { path: 'register', component: RegisterPageComponent },
  // { path: '', component: HomePageComponent },
  // { path: '**', component: NotFoundPageComponent }
  { path: '', component: ShutdownPageComponent },
  { path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
