import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomePageComponent } from './home-page/home-page.component';
import { ApiPageComponent } from './api-page/api-page.component';
import { UkkPageComponent } from './ukk-page/ukk-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ShutdownPageComponent } from './shutdown-page/shutdown-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    ApiPageComponent,
    UkkPageComponent,
    NotFoundPageComponent,
    RegisterPageComponent,
    ShutdownPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
