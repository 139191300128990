<div class="p-5 mb-4 rounded-3">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="text-center"> Kuusitunneli.fi </h1>
                <p class="text-center lead"><br> Palvelu suljettu 31.12.2022 alkaen</p>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <p> Kuusitunneli.fi ilmainen IPv6-tunneli palvelu on tullut tiensä päähän ja olemme päättäneet lopettaa sen
                ylläpidon 31.12.2022. Poistamme kaikki henkilötiedot viimeistään 30.6.2023. </p>
            <h2>Miksi palvelu on lopetettu?</h2>
            <p>Palvelua ei ole kehitetty vuosiin ja ylläpidosta ei löytynyt resurssia palvelun kehitykseen. Route48 tullessa Suomeen huomasimme että meidän oma palvelu ei vastaa sitä mitä voisi olla.</p>
            <h2> Onko suositeltavissa korvaavaa palvelua?</h2>
            <p> Alla joitakin suosituksia mihin suosittelemme siirtymään. Ensisijaisesti suosittelemme käyttämään
                natiivista IPv6-osoitetta mikäli operaattori jo tarjoaa. </p>
            <p> Telialla ei ole natiivista IPv6-osoitetta vielä omille asiakkaille tarjolla mutta on tarjonnut vuosia
                6rd-palvelua DHCP:llä.</p>
            <h3> Route48 </h3>
            <p> Suomessa on juuri avattu <a target="_blank" rel="noopener" href="https://route48.org/">Route48</a>:n
                liitetty node joka
                tarjoaa täysin vastaavaa 6in4-tunnelointia joten emme kilpaile heidän kanssa </p>
            <ul class="list-group list-group-flush">
                <li>/48 prefixin reititysverkot tuettuna joka mahdollistaa useita aliverkkoihin reitityksen.
                    Kuusitunneliin oli tämä ominaisuus suunnitteilla ja testauksessa mutta ei saatu toimimaan
                    luotettavasti.</li>
                <li>Wireguard mahdollistaa IPv6-tunneloinnin ilman julkista IPv4-osoitetta - toimii loistavasti NAT:in
                    yli ja 4G-yhteyksien yli.</li>
                <li><a target="_blank" rel="noopener" href="https://www.zerotier.com/">ZeroTier</a> -verkkoon
                    liittyminen. Voit liittää palvelun nykyiseen ZeroTier-verkkoosi. </li>
                <li>Reverse DNS-hallinta suoraan hallintapaneelista, ohjaten joko omille nimipalvelimille tai käyttäen
                    heidän tarjoamaa nimipalvelimia. Meillä tämä vaatisi omat nimipalvelimet ja ohjauksen sai kysymällä
                    sähköpostilla.</li>
                <li>BGP -tuki. Mahdollisuus oman IPv6 aliverkon mainostukseen Route48 palvelun kautta.</li>
            </ul>
            <p>
                Route48 toimii useiden sponsorien toimesta ympäri maailmaa, Suomessa tällä hetkellä <a target="_blank"
                    rel="noopener" href="https://www.web1.fi/">Web1</a>:sellä on Turussa yksi sponsoroitu node.
            </p>
            <h3>Trex:in Teredo</h3>
            <p>Tampereella <a target="_blank" rel="noopener" href="https://www.trex.fi/service/teredo.html">Trex
                    (Tampere Regional Exchange)</a> tarjoaa Teredo-ratkaisulla IPv6-tunnelointia.</p>
            <h3>Tunnelbroker</h3>
            <p>Hurricane Electricin tarjoama Tunnelbroker-palvelu on yksi vaihtoehtoinen palvelu. Heillä ei
                valitettavasti ole Suomessa sijaintia joten lähin löytyy Tukholmasta. </p>
            <h2>Voinko siirtää nykyisen käyttämäni IPv6-verkon uuteen palveluun?</h2>
            <p> Valitettavasti tämä ei onnistu vaan IP-osoitteet palautuvat palveluntarjoajallemme
                uudelleenkäytettäväksi.</p>
            <h2>Minulla on kysyttävää?</h2>
            <p>Laitathan sähköpostia admin [at] kuusitunneli.fi osoitteeseen niin vastaamme kaikkiin kysymyksiin.</p>
        </div>
    </div>