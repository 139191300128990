<div class="jumbotron jumbotron-background-image">
	<h1 class="text-center"> Kuusitunneli.fi </h1>
	<p class="text-center lead"><br></p>
	<p class="text-center"><a class="btn btn-primary btn-lg" routerLink="/register">Rekisteröidy nyt</a></p>
</div>
<div class="container">
	<div class="row">
		<div class="col-md-6">
		<h2 class="text-center"> Mikä Kuusitunneli.fi on? </h2>
		<p class="lead"> Kuusitunneli.fi on IPv6-yhdyskäytävää tarjoava palvelu, joka mahdollistaa pääsyn IPv6-verkkoon IPv4:sen yli. Helpon hallintapaneelin ansiosta ja asennusohjeiden avulla pääset nopeasti alkuun.
		<br><br> 
		</p>
		</div>
		<div class="col-md-6">
		<h2 class="text-center"> Miksi IPv6? </h2>
		<p class="lead"> IPv4-osoitteita on noin 4.3 miljardia ja meillä jo yli 5 miljardia mobiililaitetta internetissä. IPv6-osoitteiden käyttöönottoa on viivytettä osoitemuutoksen eli NAT:in avulla. Jos emme ota IPv6-osoitteita käyttöön, niin internetin kasvu pysähtyy ja useimmat jäävät sen ulkopuolelle.
		<br><br>Siirtymällä käyttämään IPv6-osoitteita mahdollistat jatkossa kaksisuuntaisen internet yhteyden ja internetin kasvamisen jatkossa.
		</p></div>
	</div>
</div>
<div class="container">
	<div class="row">
		<div class="col-md-6">
		<img style="max-width:100%;" alt="Kuvankaappaus Googlen IPv6-tilastosta 20.8.2018" src="/assets/ipv6-adoption-17-9-2017-google.png">
		</div>
		<div class="col-md-6">
			<h2 class="text-center"> Nykytilanne</h2>
			<p class="lead"> Tällä hetkellä Googlen mukaan IPv6-osoitteita on käytössä suomalaisilla noin 19% kokonaisuudessaan. Kuten Googlen sivuilta kuvankaappauksesta näkee, niin IPv6-verkko tuo parannuksia viiveisiin, keskimäärin jopa -20ms.
			<br>
			<br> Tänäpäivänä useimmat verkkosivut toimivat hyvin IPv6-osoitteilla, esimerkiksi Googlen ja Facebookin-palvelut. Suomalaisia palveluja esimerkiksi MTV3.fi toimii jo IPv6-osoitteilla.
			</p>
		</div>
	</div>
</div>
<div class="container">
	<div class="row">
		<div class="col-md-12">
		<h2 class="text-center"> Huippunopea yhteys </h2>
		<p class="lead"> Yhdyspisteemme sijaitsee Helsingin Pasilassa ja on yhteydessä 1Gbit/s-nopeudella internetin solmupisteelle, josta on suorat yhteydet kaikkiin suomalaisten teleoperaattorien verkkoon sekä suora yhteys Frankfurtiin Cinian C-lion merikaapelia pitkin.</p>
		</div>
	</div>
</div>
<div class="container">
    <div class="row">
		<div class="col-md-6">
			<h2 class="text-center"> Abuse</h2>
			<p class="lead"> Ilmoita väärinkäytöksistä <a routerLink="/contact"> lomakkeella</a> </p>
		</div>
	</div>
</div>