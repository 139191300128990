<div class="container">
    <h1> UKK </h1>
    <p> Täälä on vastaukset yleisempiin vastattuihin kysymyksiin.
    </p>
    <h3> Mikä on kuusitunneli.fi? </h3>
    <p> Se on IPv6-tunnelia tarjoava suomalainen palvelu. Tarjoamme käyttäjille IPv6-osoitteita ja yhdyskäytävän
        IPv6-tunnelin kautta IPv6-verkkoon. </p>
    <h3> Miksi kuusitunneli.fi? </h3>
    <p>Koska teleoperaattorit tarjoavat rajatusti IPv4-osoitteita ja ei vielä IPv6-osoitteita, joita on paljon enemmän.
        Tulemme tarvitsemaan IPv6-osoitteita, kun internetissä laitteiden määrä kasvaa.
        <br>
        <br>Kuusitunneli.fi tarjoaa väliaikaisen ratkaisun ennen siirtymistä kokonaan IPv6-toimivaan verkkoon.
        Tunneliyhteys ei koskaan ole samanlainen kuin natiiviyhteys mutta se on silti suhteellisen hyvä ennen kuin
        teleoperaatorisi on valmis tarjoamaan IPv6-osoitteita.
    </p>
    <h3> Toimiiko palvelu yhteydessäni? </h3>
    <p>Palvelu toimii kaikkien teleoperaattorin verkossa Suomessa. Palvelu ei toimi ulkomaalaisilla IP-osoitteilla.
        Yhteys vaatii toimiakseen julkisen IPv4-osoitteen ja päätelaitteen joka tukee 6in4-tunnelointia. Palomuurista
        tulee olla auki sisälle ja ulospäin Protokola 41. Kun käytössä dynaaminen IPv4-osoite se tulee päivittää DynDNS
        tavoin rajapintaan niin yhteys toimii.
        <br>
        <br> Mikäli käytössänne ei ole julkista IPv4-osoitetta, palvelu ei välttämättä toimi tällöin yhteydessä.
        Suosittelemme tällöin esimerkiksi Teredo yhteyttä, jonka esimerkiksi tarjoaa <a target="_blank"
            href="http://www.trex.fi/service/teredo.html">Trex.</a>
    </p>
    <h3> Mitä eroa palvelusta on verrattuna muihin IPv6-tunneleihin?</h3>
    <p> IPv6:ta voidaan tunneloida useilla eri tavoilla. 6in4, 6rd ja 6to4 käyttävät kaikki UDP 41 protokolaa. Sitten on
        vielä Teredo esimerkiksi käytettävissä.
        <br>
        <br> <strong> 6to4 </strong> perustuu täysin julkiseen IPv4-osoitteesta käännettynä heksidesimaaliksi ja
        lisäämällä sen IPv6-prefixiin 2002::/16. Tälläin IPv4-osoitteen muuttuessa muuttuu myös IPv6-osoite. Tämäkin
        yhteystapa vaatii, että asiakkaan laitteessa on julkinen IPv4-osoite. 6to4 ongelmana on asymmetrinen reititys,
        joka tarkoittaa että on useita yhdyskäytäviä natiivi IPv6-verkkoon ja siitä poispäin. Lisäksi ongelmana, että
        6to4 yhdyskäytävällä on useita organisaatiossa ylläpitämässä ja jotkut luokittelevat näitä osoitteita ns.
        "kakkosluokan" osoitteiksi. Yhteyspisteitä löytyy ainakin Funetilta ja Elisan verkoista.
        <br>
        <br> <strong> 6in4 </strong> on staattinen IPv6-tunneli IPv4:sen yli tarjoten kiinteitä IPv6-osoitteita. Koska
        pääasiassa kuluttajilla on dynaamisia IPv4-osoitteita käytössä, vaatii että kuluttaja päivittää rajapinnan yli
        voimassaolevan IPv4-osoitteen. Meidän palvelumme käyttää 6in4 protokolaa.
        <br>
        <br> <strong> 6rd </strong> (Rapid Deployment) on teleoperaattorien tapa tarjota IPv6-osoitteita IPv4-verkon
        yli. Osoitteiden jakaminent tapahtuu samalla tavalla kuin 6to4-verkossa paitsi tässä käytetään vain
        teleoperaattorin omaa yhteyspistettä ja se että käytetään teleoperaattorin omia osoitteita. Usein tämä
        yhteystapa onkin nopein, koska yhdyskäytävä sijaitsee yleensä oman teleoperaattorin verkossa. Teleoperaattori
        Telia tarjoaa tätä palvelua esimerkiksi kiinteässä laajakaista verkossaan.
        <br>
        <br> <strong> Teredo </strong> Teredo on näistä kolmesta aikaisemmasta protokolasta erilaisempi, sillä se
        käyttää UDP-protokolaa ja toimii myös osoitemuutoksen (NAT) takana ilman julkista IP:tä. Lähin julkinen Teredo
        palvelu löytyy <a target="_blank" hreflang="en" rel="external"
            href="http://www.trex.fi/service/teredo.html">Trex</a>:iltä.
        <br>
    </p>
    <h3> Miten saan reitittimelle osoitemuutoksen (NATIn) tai sisäverkon? </h3>
    <p> IPv6:sessa ei tarvita osoitemuutosta ollenkaan, sillä osoitteita on niin paljon, että mekin pystymme jakamaan
        näitä ilmaiseksi käyttäjillemme käyttöön. Meillä on osoitteita varattu jokaiselle suomalaiselle miljoonia, joten
        emme usko että osoitteista tulee pulaa kenelekkään.
        <br>
        <br> Suosittelemme kuitenkin rajoittamaan reitittimen ja päätelaitteiden palomuurista sisällepäin liikennöinnin,
        kuten IPv4-puolella tulee tehdä. Useimmat laitteilla on oletuksena suljettuna sisällepäin tuleva liikenne.
    </p>
    <h3> Yhteys palveluun on hidas! </h3>
    <p> Koska kyseessä yleisesti on tunneliyhteydestä, sen nopeus ei ole sama kuin natiivinen yhteys. Tähän vaikuttaa
        sinun yhteytesi palvelimellemme, yleinen verkon kuormitus ja ruuhka. Voit testata nopeutta <a target="_blank"
            href="http://www.speedtest.net/"> Speedtest.net</a>:issä kun valitset palvelimeksi Elisa Tallinn, joka on
        tällä hetkellä lähin, joka tukee IPv6-osoitteella nopeustestiä.</p>
    <h3> Onko palvelussa kaikki portit avoinna? </h3>
    <p> Kyllä, poikkeuksellisesti portti TCP 25 (SMTP) on suljettu ulospäin menevältä liikenteeltä. Tästä syystä tulisi
        käyttää palveluntarjoajanne omaa SMTP-palvelinta ja salattuja portteja 465 tai 587. Vaikka sisällepäin on yhteys
        avoinna, niin emme suosittele palvelinten ylläpitoa tunmelin päässä. Yhteys on tarkoitettu yksityiseen käyttöön.
    </p>
    <h3> Tarvitsen apua? </h3>
    <p> Kuusitunneli.fi tarjoaa vain alustan, ei asiakaspalvelua. Toivottavasti sivustolla löytyvistä ohjeista on
        sinulle apua. </p>
    <h3> Kuinka turvallinen palvelu on? </h3>
    <p> Sinun tulee ymmärtää, ettei kyseessä ole VPN-palvelu. Pidämme käyttäjä ja IP-osoite lokia käyttäjistämme ja
        oikeuden päätöksestä luovutamme viranomaisille. Emme seuraa yksittäisten käyttäjiemme liikennettä, mutta
        liikennemääriä voimme mahdollisesti seurata. Palvelussamme kaikki tietoliikenne kulkeutuu erikseen
        salaamattomana ellet erikseen salaa. Suosittelemme varmistamaan kirjautuessa erityisesti verkkopalveluihin että
        käytössä on HTTPS-yhteys. Meidän tehtävänä ei ole nuuskia käyttäjiemme liikennettä ja kunnoitamme yksityisen
        suojaa käyttöehtojemme mukaisesti.
    </p>
    <h3> Tarjoatteko RDNS (Reverse DNS) palvelua? </h3>
    <p> Kyllä, ota yhteyttä <a href="https://kuusitunneli.fi/contact"> lomakkeella </a> ja ilmoita tunnelisi ID ja
        nimipalvelimet (vähintään kaksi) missä sinulla on nimipalvelut niin ohjataan RDNS toimimaan nimipalvelimellasi.
        Hallinta tapahtuu siis omalla nimipalvelimellasi. </p>
    <h3> Onko palvelu ilmainen? </h3>
    <p> Toistaiseksi ainakin, koskaan ei tiedä mitä tulevaisuudessa tapahtuu. Tällä hetkellä yhteyksiä sponsoroi <a
            target="_blank" href="https://datatekniikka.fi"> Turun Datatekniikka Oy </a>
    </p>
</div>