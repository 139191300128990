import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-ukk-page',
  templateUrl: './ukk-page.component.html',
  styleUrls: ['./ukk-page.component.css']
})
export class UkkPageComponent implements OnInit {

  constructor(
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('UKK - Kuusitunneli.fi');
  }

}
